import React from "react";

function Index2() {
  return (
    <>
      <div className="px-4 md:px-[6.25vw] break-words s-m">
        <section className="mt-7 mb-7 s1">
          <span className="text-[#3A3941] text-[.75rem] md:text-[1rem]">
            A HONO HR Innovations Newsletter
          </span>
          <h1 className="hd ff-hd1 text-[#3A3941] text-[1.75rem] md:text-[3rem] leading-none">
            HUMAN FACTOR
          </h1>
          <img src="images/logo.png" alt="" className="w-full mb-4" />
        </section>

        <section className="s2 mb-7 mt-7">
          <ul className="m-0 p-0 flex flex-wrap">
            <li className="leading-none">INAUGURAL ISSUE</li>
            <li className="leading-none">SEPTEMBER 2022</li>
            <li className="leading-none">
              <a href="#"> VIEW ON BROWSER</a>
            </li>
          </ul>
        </section>

        <section className="s3 pt-9 pb-9 text-[#fff]">
          <h2 className="hd text-[1.75rem] md:text-[3rem]">SCHRÖDINGER’S CAT</h2>
          <h3 className="hd2 text-[1rem] md:text-[1.75rem]">
            TALENT – THERE & NOT THERE
          </h3>
          <img src="images/img1.png" alt="" className="w-full mt-9 mb-9" />
          <h3 className="hd text-[1.75rem] md:text-[3rem]">
            WHERE HAVE ALL THE PEOPLE GONE?
          </h3>
          <img src="images/img-cat.png" alt="" className="w-full" />
        </section>

        <section className="s4 pb-7 pt-7">
          <h3 className="text-[1.75rem] mb-4">Randeep Singh</h3>
          <div className="border-b border-dotted border-[#c4547c] mb-4"></div>
          <p className="mb-3">
            What has really changed in the world of talent discovery and talent
            acquisition? Where has all the great talent that drives businesses
            suddenly gone? Most HR leaders are faced with a talent pool, which
            is like the proverbial Schrödinger’s Cat, both there and not there.
            Here’s a look at changing behavioural trends in potential hires, and
            an attempt to find answers to the big hiring question...
          </p>
          <a href="#" className="text-[#c4547c] text-[1.5rem]">
            Read more
          </a>
        </section>

        <section className="s5">
          <div className="grid grid-cols-1 tsm:grid-cols-2 md:grid-cols-2 gap-y-5 gap-x-5">
            <div className="s5-bx overflow-hidden">
              <img src="images/img2.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  Silent Quit: Is It Subversive Response To Corporate Culture?
                </h3>
                <p className="mb-4">
                  Appearing analogous to the passive aggressive response in
                  relationships, silent quit is the big new wave that is
                  sweeping across corporations around the world.....
                </p>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
            <div className="s5-bx overflow-hidden">
              <img src="images/img3.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  The Arithmetic of Sentiment: The Millennial HR Needs A
                  Sentiment Mapping System
                </h3>
                <p className="mb-4">
                  When even evolved businesses with healthy checks and balances
                  and what were seen as 'progressive HR practices begin to lose
                  people...
                </p>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
          </div>
        </section>

        <section className="s6 pt-9 pb-5">
          <h3 className="ff-hd2 text-[1.75rem] mb-4">MORE STORIES</h3>
          <img src="images/img4.png" alt="" className="w-full mb-5" />

          <h3 className="text-[1.75rem] mb-4 ff-hd2">Team HONO</h3>
          <div className="border-b border-dotted border-[#c4547c] mb-4"></div>
          <p className="mb-3">
            We ran a survey online across platforms, to understand what most
            defined happiness at the millennial workplace - and the results were
            surprisingly NOT 'better pay' - something organisations tend to see
            as a universal remedy to employee retention problems.
          </p>
          <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
            Read more
          </a>

          <div className="s7 bg-[#fff] mt-5 pt-5 pl-5 pr-5 pb-5 leading-normal">
            <div>
              <h3 className="text-[1.3rem] mb-4 ff-hd2">
                HR Tactical Hustle: If You Can’t Find the Right Talent, Do This
              </h3>
              <p className="mb-3">
                hat do you do if you have a critical position to fill, but just
                can’t find the right person for the job? Here’s a lateral
                thought that can actually break the impasse.
              </p>
              <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                Read more
              </a>
              <div className="border-b border-dotted border-[#c4547c] mb-4 mt-4"></div>
            </div>
            <div>
              <span className="inline-block bg-[#c4547c] p-1 pr-9 ff-hd2 text-[#fff] text-[1.2rem] mb-3">
                Future Watch
              </span>
              <h3 className="text-[1.3rem] mb-4 ff-hd2">
                21 Roles Of Human Resources In 10 Years
              </h3>
              <p className="mb-3">
                The future holds the promise of a whole new schema of
                businesses, accompanied by new kinds of jobs – the most critical
                being new roles for the Human Resource functions.
              </p>
              <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                Read more
              </a>
              <div className="border-b border-dotted border-[#c4547c] mb-4 mt-4"></div>
            </div>

            <div>
              <span className="inline-block bg-[#c4547c] p-1 pr-9 ff-hd2 text-[#fff] text-[1.2rem] mb-3">
                HR ALERT
              </span>
              <h3 className="text-[1.3rem] mb-4 ff-hd2">
                FBI Warning: Phantom People May Be Getting Hired For Tech Jobs
              </h3>

              <div className="block md:flex gap-x-5">
                <div className="w-auto md:w-[40vw]">
                  <img src="images/img5.png" alt="" className="w-full" />
                </div>

                <div className="w-auto md:w-[60vw]">
                  <p className="mb-3">
                    An unreal colleague, who could be a potential scammer, and
                    have worked his way through remote interviews and hiring!
                    How does that sound?
                  </p>
                  <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                    Read more
                  </a>
                </div>
              </div>
              <div className="border-b border-dotted border-[#c4547c] mb-4 mt-4"></div>
            </div>

            <div>
              <h3 className="text-[1.3rem] mb-4 ff-hd2">
                Small Innovation, Big Impact: HAPACS
              </h3>
              <p className="mb-3">
                It’s a nifty HR-Tech innovation, creating an Attendance &
                Personnel Access Control System that can have a butterfly effect
                on workforce management.
              </p>
              <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                Read more
              </a>
              <div className="border-b border-dotted border-[#c4547c] mb-4 mt-4"></div>
            </div>
            <div>
              <span className="inline-block bg-[#c4547c] p-1 pr-9 ff-hd2 text-[#fff] text-[1.2rem] mb-3">
                TREND
              </span>
              <h3 className="text-[1.3rem] mb-4 ff-hd2">
                Are You For Or Against The New BIG Trend Of Career Hoarding?
              </h3>
              <p className="mb-3">
                Career hoarding is an obvious fallout of the hybrid work and
                silent quitting, as today’s worker multi-tasks and hold multiple
                jobs. It works as a safety net for employees, but it may also
                signal an early burnout.
              </p>
              <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                Read more
              </a>
              <div className="border-b border-dotted border-[#c4547c] mb-4 mt-4"></div>
            </div>
            <div>
              <h3 className="text-[1.3rem] mb-4 ff-hd2">
                The Big Question Of Lost ‘Loyalty’ In Workplace
              </h3>
              <p className="mb-3">
                Tracing the concept of ‘loyalty’ from the post-war era to the
                post-pandemic one, when it is now under severe stress – and
                perhaps – just a nostalgic word. What next?
              </p>
              <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                Read more
              </a>
            </div>
          </div>
        </section>

        <section className="s8 pt-7 pb-7">
          <h3 className="text-[1.75rem] mb-4">PERSONAL WORKPLACE STORIES</h3>
          <h4 className="ff-hd2 text-[1.4rem]">
            Anecdotal Evidence Of New Workforce Trends
          </h4>
          <div className="border-b border-dotted border-[#fff] mb-4 mt-4"></div>

          <div className="grid grid-cols-1 tsm:grid-cols-2 md:grid-cols-2 gap-y-5 gap-x-5">
            <div className="s8-bx overflow-hidden">
              <img src="images/img6.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  “It Just Means Not Burning Yourself Out”: Real Stories Of
                  Quiet Quitters
                </h3>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
            <div className="s8-bx overflow-hidden">
              <img src="images/img7.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  "I Resign, Mazaa Nahi Aa Raha": Harsh Goenka Shares Quit Note
                </h3>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
            <div className="s8-bx overflow-hidden">
              <img src="images/img8.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  Bangaluru: Mass Resignations On Being Asked To Work From
                  Office
                </h3>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
            <div className="s8-bx overflow-hidden">
              <img src="images/img9.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  Starbuck CEO On His knees To Get Employees Back In Office
                </h3>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
          </div>
        </section>

        <section className="s9 pt-5 pb-5">
          <div className="grid grid-cols-1 tsm:grid-cols-2 md:grid-cols-3">
            <div className="s9-bx overflow-hidden">
              <img src="images/img10.png" alt="" className="w-full h-full" />
            </div>
            <div className="s9-bx overflow-hidden">
              <img src="images/img11.png" alt="" className="w-full h-full" />
            </div>
            <div className="s9-bx overflow-hidden">
              <img src="images/img12.png" alt="" className="w-full h-full" />
            </div>
            <div className="s9-bx overflow-hidden">
              <img src="images/img13.png" alt="" className="w-full h-full" />
            </div>
            <div className="s9-bx overflow-hidden">
              <img src="images/img14.png" alt="" className="w-full h-full" />
            </div>
            <div className="s9-bx overflow-hidden">
              <img src="images/img15.png" alt="" className="w-full h-full" />
            </div>
          </div>

          <div className="bg-[#fff] p-5">
            <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-5 mt-5">
              What Do You Think Millennial Wants Most From Work & Workplace?
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
              <div className="text-[1.50rem] leading-normal ff-hd2">
                Social Impact & Social Value Recognition Work-life balance
              </div>
              <div className="text-[1.50rem] leading-normal ff-hd2">
                Great fraternity & Learning Opportunity Long-term Job Security
              </div>
            </div>

            <div className="text-right mt-5">
              <a
                href="#"
                className="bg-[#c4547c] p-1 pr-4 pl-4 ff-hd2 text-[#fff] text-[1.75rem] ff-hd2"
              >
                Click To Respond
              </a>
            </div>
          </div>
        </section>

        <section className="s10 bg-[#fff] p-5">
          <div className="grid grid-cols-1 tsm:grid-cols-2 md:grid-cols-2 gap-y-5 gap-x-9">
            <div className="s10-bx overflow-hidden">
              <img src="images/img16.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  ARASMAS: HR-Tech Innovation That Solves Productivity
                  Pain-Points
                </h3>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
            <div className="s10-bx overflow-hidden">
              <img src="images/img17.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  CHRO SPENCER’S: On Using HR-Tech To Cut Attrition Rate By 30%
                </h3>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
            <div className="s10-bx overflow-hidden">
              <img src="images/img18.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  HONO Trail-blazes At SHRMTech22
                </h3>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
            <div className="s10-bx overflow-hidden">
              <img src="images/img19.png" alt="" className="w-full" />
              <figcaption className="pd-5 bg-[#fff] h-full p-5">
                <h3 className="hd4 text-[1.50rem] leading-normal ff-hd2 mb-4">
                  Purple Patch At TECHR INDIA
                </h3>
                <a href="#" className="text-[#c4547c] text-[1.5rem] ff-hd2">
                  Read more
                </a>
              </figcaption>
            </div>
          </div>
        </section>

        <section className="s11 p-7 bg-[#333]">
          <h2 className="ff-hd1 text-[1.75rem] md:text-[3rem] tracking-[.25em] text-[#fff]">
            THOUGHT LEADERSHIP
          </h2>
          <h3 className="ff-hd2 text-[1rem] md:text-[1.75rem] tracking-[.25em] text-[#fff]">
            “DOES YOUR PEOPLE TECHNOLOGY DRIVE MEASURABLE BUSINESS IMPACT?”
          </h3>
          <a href="#">
            <img src="images/img21.png" className="w-full mt-9" alt="" />
          </a>
        </section>

        <section className="s12 p-7">
          <ul className="m-0 p-0 flex flex-wrap">
            <li className="leading-none">
              <a href="#"> Contact Us</a>
            </li>
            <li className="leading-none">
              <a href="#"> Follow Us On Facebook</a>
            </li>
            <li className="leading-none">
              <a href="#"> I nstagram</a>
            </li>
            <li className="leading-none">
              <a href="#"> YouTube</a>
            </li>
            <li className="leading-none">
              <a href="#"> Twitter</a>
            </li>
            <li className="leading-none">
              <a href="#"> Share</a>
            </li>
          </ul>
        </section>

        <section className="mt-7 mb-7 s1">
          <span className="text-[#3A3941] text-[.75rem] md:text-[1rem]">
            A HONO HR Innovations Newsletter
          </span>
          <h1 className="hd ff-hd1 text-[#3A3941] text-[1.75rem] md:text-[3rem] leading-none">
            HUMAN FACTOR
          </h1>
          <img src="images/logo.png" alt="" className="w-full mb-9" />
        </section>

        <section className="s2 mb-7 mt-7">
          <ul className="m-0 p-0 flex flex-wrap">
            <li className="leading-none">INAUGURAL ISSUE</li>
            <li className="leading-none">SEPTEMBER 2022</li>
            <li className="leading-none">
              <a href="#"> VIEW ON BROWSER</a>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
}

export default Index2;
